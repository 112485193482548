<template>
  <!-- VAppBar - Vuetify -->
  <v-app-bar id="app-bar" class="mt-0" absolute app color="#F9F9F9" flat height="75">
    <h2 :class="['font-medium', fontDate]">
      {{ toDay }}
    </h2>
    <!-- VSpacer - Vuetify -->
    <h2 class="font-medium text-lg ml-10">
      {{ toDayTime }}
    </h2>
    <!-- VMenu - Vuetify -->
    <v-spacer />
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <!-- VList - Vuetify -->
      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-spacer />

    <span class="black--text font-normal">{{ userProfile.name }}</span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <!-- VButton - Vuetify -->
        <v-btn min-width="0" text class="mx-2" fab dark v-bind="attrs" v-on="on">
          <!-- VAvatar - Vuetify -->
          <v-avatar>
            <img :src="userProfile.profileURL" />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="goToProfile">
          <v-icon class="mr-2"> mdi-account-box-multiple-outline </v-icon>
          <v-list-item-title>Tài khoản</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="onClickHelp">
          <v-icon class="mr-2"> mdi-help-circle </v-icon>
          <v-list-item-title>Trợ giúp</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="logout">
          <v-icon class="mr-2"> mdi-logout-variant </v-icon>
          <v-list-item-title>Đăng xuất</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import { daysOfWeek, SESSION_STORAGE_KEY } from "~/utils/constants.js";
import { AuthService } from "~/services/auth.service";

export default {
  name: "Navigation",
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userProfile: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    today: new Date(),
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    fontDate() {
      return window.innerWidth <= 640 ? "text-sm" : "text-lg";
    },
    toDay() {
      const dayInWeek = daysOfWeek[this.today.getDay()];
      return window.innerWidth > 640
        ? dayInWeek +
            ", " +
            "ngày " +
            this.today.getDate() +
            " tháng " +
            (this.today.getMonth() + 1) +
            " năm " +
            this.today.getFullYear()
        : dayInWeek +
            ", " +
            this.today.getDate() +
            "/" +
            (this.today.getMonth() + 1) +
            "/" +
            this.today.getFullYear();
    },
    toDayTime() {
      const mins =
        this.today.getMinutes() < 10
          ? "0" + this.today.getMinutes()
          : this.today.getMinutes();
      return this.today.getHours() + ":" + mins;
    },
  },
  created() {},

  mounted() {
    setInterval(() => {
      this.today = new Date();
    }, 1000);
  },
  methods: {
    logout() {
      const prescriptionId = sessionStorage.getItem(SESSION_STORAGE_KEY.PRESCRIPTION_ID);
      if (prescriptionId) {
        sessionStorage.removeItem(SESSION_STORAGE_KEY.PRESCRIPTION_ID);
      }
      AuthService.makeLogout();
    },
    goToProfile() {
      this.$router.push({
        path: `/tai-khoan`,
      });
    },
    onClickHelp() {
      this.$store.commit("setStepGuide", 0);
    },
  },
};
</script>
